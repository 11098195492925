/*eslint sort-keys: ["error", "asc", {natural: true}]*/
import Experiment from 'utils/experiments/experiment';

export const VARIANTS = {
  CHECKOUT_REDIRECT: 'LP to booking page',
  CONTROL: 'Control',
  CONTROL1: 'Control 1',
  CONTROL2: 'Control 2',
  SHOWPAGE_REDIRECT: 'LP to showpage',
  TREATMENT: 'Treatment',
};

const BOOSTERS_EXPERIMENT = 'POI Card Booster Experiment';
const LAST_MINUTE_FILTERS_EXPERIMENT = 'Last Minute Filters Experiment';
const LFC_IMPACT = 'LFC Impact';
const LTD_LP_Experiment = 'LTD LP Experiment';
const SHOULDER_PAGE_SECTION_RANKING_EXPERIMENT =
  'Shoulder Page Section Ranking';
const VIDEO_THUMBNAIL_IN_BANNER = 'MB Banner Video Experiment';
const SEATMAP_EXPERIMENT = 'Venue Seats Page Revamp';
const SUBATTRACTIONS_EXPERIMENT = 'Sub-Attractions Page Revamp';
const PRODUCT_CARD_VIDEO = 'Product Card Video Experiment V1';
const C1_COLLECTION_LTT_BROADWAY_PRODUCT_CARD_CTA_EXPERIMENT = 'DE IT CTA Copy';
const C1_COLLECTION_PRODUCT_CARD_CTA_EXPERIMENT_ENGLISH = 'EN LP CTA Copy v2';
const CRUISES_REVAMP = 'Cruises Revamp Experiment';

export const EXPERIMENT_NAMES: Record<string, string> = {
  BOOSTERS_EXPERIMENT,
  C1_COLLECTION_LTT_BROADWAY_PRODUCT_CARD_CTA_EXPERIMENT,
  C1_COLLECTION_PRODUCT_CARD_CTA_EXPERIMENT_ENGLISH,
  CRUISES_REVAMP,
  LAST_MINUTE_FILTERS_EXPERIMENT,
  LFC_IMPACT,
  LTD_LP_Experiment,
  PRODUCT_CARD_VIDEO,
  SEATMAP_EXPERIMENT,
  SHOULDER_PAGE_SECTION_RANKING_EXPERIMENT,
  SUBATTRACTIONS_EXPERIMENT,
  VIDEO_THUMBNAIL_IN_BANNER,
};

/**
 * Experiments List
 * Ensure Experiment Variant at index 0 is always Control (Current) Variant.
 */
export const EXPERIMENTS: Record<string, Experiment> = {
  [BOOSTERS_EXPERIMENT]: new Experiment(
    BOOSTERS_EXPERIMENT,
    [VARIANTS.CONTROL, VARIANTS.TREATMENT],
    [50, 50]
  ),
  [C1_COLLECTION_LTT_BROADWAY_PRODUCT_CARD_CTA_EXPERIMENT]: new Experiment(
    C1_COLLECTION_LTT_BROADWAY_PRODUCT_CARD_CTA_EXPERIMENT,
    [VARIANTS.CONTROL, VARIANTS.TREATMENT],
    [100, 0]
  ),
  [C1_COLLECTION_PRODUCT_CARD_CTA_EXPERIMENT_ENGLISH]: new Experiment(
    C1_COLLECTION_PRODUCT_CARD_CTA_EXPERIMENT_ENGLISH,
    [VARIANTS.CONTROL, VARIANTS.TREATMENT],
    [100, 0]
  ),
  [CRUISES_REVAMP]: new Experiment(
    CRUISES_REVAMP,
    [VARIANTS.CONTROL, VARIANTS.TREATMENT],
    [50, 50]
  ),
  [LAST_MINUTE_FILTERS_EXPERIMENT]: new Experiment(
    LAST_MINUTE_FILTERS_EXPERIMENT,
    [VARIANTS.CONTROL, VARIANTS.TREATMENT],
    [0, 100]
  ),
  [LFC_IMPACT]: new Experiment(
    LFC_IMPACT,
    [VARIANTS.CONTROL, VARIANTS.TREATMENT],
    [100, 0]
  ),
  [LTD_LP_Experiment]: new Experiment(
    LTD_LP_Experiment,
    [VARIANTS.SHOWPAGE_REDIRECT, VARIANTS.CHECKOUT_REDIRECT],
    [0, 100]
  ),
  [PRODUCT_CARD_VIDEO]: new Experiment(
    PRODUCT_CARD_VIDEO,
    [VARIANTS.CONTROL, VARIANTS.TREATMENT],
    [100, 0]
  ),
  [SEATMAP_EXPERIMENT]: new Experiment(
    SEATMAP_EXPERIMENT,
    [VARIANTS.CONTROL, VARIANTS.TREATMENT],
    [50, 50]
  ),
  [SHOULDER_PAGE_SECTION_RANKING_EXPERIMENT]: new Experiment(
    SHOULDER_PAGE_SECTION_RANKING_EXPERIMENT,
    [VARIANTS.CONTROL, VARIANTS.TREATMENT],
    [0, 100]
  ),
  [SUBATTRACTIONS_EXPERIMENT]: new Experiment(
    SUBATTRACTIONS_EXPERIMENT,
    [VARIANTS.CONTROL, VARIANTS.TREATMENT],
    [50, 50]
  ),
  [VIDEO_THUMBNAIL_IN_BANNER]: new Experiment(
    VIDEO_THUMBNAIL_IN_BANNER,
    [VARIANTS.CONTROL, VARIANTS.TREATMENT],
    [100, 0]
  ),
};
